














































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import moment from "moment";

export default defineComponent({
  props: {
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },
    sms: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const model = reactive({
      text: props.sms.text,
      sender: "",
      executionDate: "",
      participantsIds: [],
    });
    const state = reactive({
      limitPerSMS: 160,
      smsCount: 0,
      shortSMS: false,
      error: false as boolean | number,
      minTime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    });

    const isValid = computed(() => {
      if (
        model.sender &&
        model.sender.length >= 3 &&
        model.sender.length <= 11 &&
        model.text
      ) {
        return true;
      } else return false;
    });

    const countSMSlength = () => {
      const specialChars = ["ą", "ę", "ś", "ć", "ń", "ż", "ź", "ó", "ł"];
      const smsLength = model.text.length;
      for (const t in specialChars) {
        if (
          model.text.toLowerCase().indexOf(specialChars[t].toLowerCase()) >= 0
        ) {
          state.limitPerSMS = 70;
          state.shortSMS = true;
        }
      }
      state.smsCount = Math.ceil(smsLength / state.limitPerSMS);
    };

    watch(() => model.text, countSMSlength);
    onMounted(countSMSlength);

    const rules = {
      required: [(v: string) => !!v || root.$t("layout.misc.required")],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        text: model.text,
        executionDate:
          model.executionDate || moment(new Date()).format("YYYY-MM-DDTHH:mm"),
        sender: model.sender,
        participantsIds: props.participants.map((el: any) => el.id),
      };

      axiosInstance
        .post(`event/${root.$route.params.id}/sms-message-trigger`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.sendSuccess"),
          });
          setTimeout(() => {
            emit("close-dialog");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    return { model, state, rules, isValid, onSubmit };
  },
});
